<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>Profile edits</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link>\ Profile edits
                </div>
              </div>
              <div class="breadcrumb-right">
                <div class="card-toolbar">
                  <v-btn x-large dark @click="viewArchivedProfileEdits()">View archived profile edits</v-btn>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <p>All profile edits are managed by the local SCORE team.</p>
            <div class="table-responsive">
             <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
                <div class="row">

                  <v-col cols="12" sm="6" md="3" v-if="currentUser && currentUser.access_type!='score'">
                    <v-autocomplete
                        label="SCORE"
                        v-model="search.score_id"
                        v-on:keyup.enter="searchUpdateRequest"
                        @input="search.score_id = $event !== null ? $event : ''"
                        item-text="name"
                        item-value="id"
                        :items="scores"
                        outlined
                        dense
                        clearable>
                      <template v-slot:no-data>
                        <div class="no-data-auto-complete text-center">No data available </div>
                      </template>
                    </v-autocomplete>
                  </v-col>

                  <v-col cols="12" sm="12" md="3">
                    <v-text-field
                        label="Search by name"
                        v-model="search.info"
                        v-on:keyup.enter="searchUpdateRequest"
                        @input="search.info = $event !== null ? $event : ''"
                        clearable
                        outlined
                        dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="3" md="3">
                    <v-menu v-model="request_date_menu" :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field  v-on:keyup.enter="searchUpdateRequest"
                            v-model="search.request_date"
                            label="Date of request" clearable
                            prepend-inner-icon="mdi-calendar"
                            readonly dense outlined
                            v-bind="attrs"
                            v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                          v-model="search.request_date"
                          @input="request_date_menu = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>

                  <v-col cols="12" sm="12" :md="currentUser.access_type == 'score' ? 6 : 3" class="text-right">
                    <v-btn
                        @click="searchUpdateRequest"
                        class="text-white btn btn-primary"
                        :loading="isLoading">
                      <v-icon small elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                      Search
                    </v-btn>
                  </v-col>
                </div>

              </div>

              <v-skeleton-loader
                  type="table-thead"
                  v-if="isLoading"
              >
              </v-skeleton-loader>

              <v-skeleton-loader
                  v-if="isLoading"
                  type="table-row-divider@25"
              >
              </v-skeleton-loader>

              <table class="table" v-if="! isLoading">
                <thead>
                <tr class="px-3">
                  <th class="px-3">Date requested</th>
                  <th class="px-3">Requested by</th>
                  <th class="px-3">Type</th>
                  <th class="px-3"  v-if="currentUser && currentUser.access_type == 'federal'">SCORE</th>
                  <th class="pr-3 text-center">Status</th>
                  <th class="pr-3 text-center">Options</th>
                </tr>
                </thead>

                <template>
                  <tr v-for="(item, index) in update_requests" :key="index" v-if="update_requests.length>0">
                      <td class="px-3">
                        <a href="#" @click="reviewRequest(item.id)" class=" ml-2 font-weight-medium d-block font-size-lg">
                       {{ item.requested_date }}
                      </a>
                      </td>
                    <td class="px-3">
                        <span class="ml-2 font-weight-bolder d-block font-size-lg">
                        <a href="#" @click="viewAccountHolderInfo(item.enroler_id)">{{ item.full_name }}</a>
                      </span>
                    </td>

                    <td class="px-3">
                        <span class="font-weight-black d-block font-size-lg">
                        {{ item.type_text }}
                      </span>
                    </td>
                    <td class="px-3"  v-if="currentUser && currentUser.access_type == 'federal'">
                        <span class=" d-block font-size-lg">
                        {{ item.score_prefix }}
                      </span>
                    </td>
                    <td class="px-3 text-center">
                           <span class="badge"
                                 v-bind:class="{ 'badge-success': item.status=='approved', 'badge-danger': item.status=='rejected','badge-warning':item.status=='received' }">
                             {{ item.status_text }}
                           </span>
                      </td>

                      <td class="pr-0 text-center">
                        <template>
                          <b-dropdown
                              size="sm"
                              variant="link"
                              toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                              no-caret
                              right
                              no-flip
                          >
                            <template v-slot:button-content>
                              <i class="ki ki-bold-more-hor"></i>
                            </template>
                            <div class="navi navi-hover min-w-md-250px">
                              <b-dropdown-text tag="div" class="navi-item">
                                <a @click="reviewRequest(item.id)" class="navi-link">
                                  <span class="navi-icon">
                                    <i class="fas fa-info-circle" style="color: #00008b"></i>
                                  </span>
                                  <span class="navi-text">Review request</span>
                                </a>
                              </b-dropdown-text>

                            </div>
                          </b-dropdown>
                        </template>
                      </td>
                    </tr>
                    <tr v-if="update_requests.length == 0">
                      <td class="text-center px-3" colspan="5">No pending profile edits</td>
                    </tr>
                </template>

              </table>

              <b-pagination
                  v-if="update_requests.length > 0"
                  class="pull-right mt-7"
                  @input="getAllUpdateRequest"
                  v-model="page"
                  :total-rows="total"
                  :per-page="perPage"
                  first-number
                  :disabled="isLoading"
                  last-number
              ></b-pagination>
            </div>
            <div>
        </div>
      </div>
        </div>
      </div>
    </div>
    <v-dialog
        v-model="dialog"
        max-width="800px"  transition="dialog-bottom-transition" scrollable>
      <v-card>
        <v-card-title>
          <span class="h4 font-weight-medium">This request was made on <strong>{{updateRequestDetail.requested_date}}</strong> by <strong>{{updateRequestDetail.full_name}}</strong></span><br>

        </v-card-title>
        <v-card-text>
          <p v-if="updateRequestDetail.email && updateRequestDetail.email!=''"><b>Email:</b> {{updateRequestDetail.email}}</p>
          <v-list>
            <template>
                <v-list-item v-if="updateRequestDetail.content.length>0" subheader v-for="(content,index) in updateRequestDetail.content">
                  <v-list-item-title>
                    <p class="font-weight-regular " v-html="content"></p>
                  </v-list-item-title>
                </v-list-item>
                <span class="h6 font-weight-regular" v-if="updateRequestDetail.content==0">No Data Changed</span>
              </template>

          </v-list>
          <v-container>
            <v-row>
              <v-col
                  cols="12">
                <ckeditor :config="editorConfig" v-model="remarks">
                </ckeditor>
              </v-col>
              <v-col cols="12">
                <v-list dense>
                  <v-subheader><span class="h5 font-weight-medium">Notes</span></v-subheader>
                  <v-list-item>
                    <v-list-item-content>
                      <p class="subtitle-1 font-weight-regular">Remarks entered here will be sent to by email to the Enroller, with the result of their change request.</p>
                    </v-list-item-content>
                  </v-list-item>
<!--                  <v-list-item>-->
<!--                    <v-list-item-content>-->
<!--                      <p class="subtitle-1 font-weight-regular">Selecting ‘Hold’ will send the State Office Remark to the Enroller, and will change the request status to ‘On Hold’. The request can then be Approved or Not approved at a later time.</p>-->
<!--                    </v-list-item-content>-->
<!--                  </v-list-item>-->
                </v-list>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
                 text class="cancel-btn"
                x-large
              @click="close">
           Close
          </v-btn>
          <v-btn dark color="red" x-large :loading="buttonLoadingDisapprove" @click="disapprovedUpdateRequest" rounded>
            <v-icon right dark small>
              fas fa-times
            </v-icon> Not approve
          </v-btn>
          <v-btn

              dark
              x-large :loading="buttonLoadingApprove"
              @click="approvedUpdateRequest" rounded>
            <v-icon right small dark>
              fas fa-check
            </v-icon> Approve
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>
<script>
import UpdateRequestService from "@/services/update-request/UpdateRequestService";
import ScoreService from "@/services/score/score/ScoreService";
const updateRequest=new UpdateRequestService;
const score=new ScoreService();
export default {
  data(){
    return{
      update_requests:[],
      search:{
        info:'',
        score_id:'',
        type:'',
        request_date:'',
      },
      total: null,
      currentUser: null,
      perPage: null,
      page: null,
      isLoading:false,
      dialog:false,
      changed_data:[],
      updateRequestDetail:{},
      request_id:'',
      scores:[],
      editorConfig: {
        scayt_autoStartup:true,
        scayt_sLang: 'en_GB',
        toolbar: [ [ 'Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'Link', 'NumberedList', 'BulletedList' ] ],
        versionCheck: false
      },
      type:'',
      types:[
        {value:'accountholder',title:'Enroller'},
        {value:'candidate',title:'Candidate'},
      ],
      remarks:'',
      request_date_menu:false,
      buttonLoadingApprove:false,
      buttonLoadingDisapprove:false,
    }
  },
  methods:{
    viewArchivedProfileEdits() {
      this.$router.push({
        name: "archived-update-requests",
      });
    },

    getAllScore() {
      score
          .getScores()
          .then(response => {
            this.scores = response.data.scores;
          });
    },
    searchUpdateRequest() {
      this.getAllUpdateRequest();
    },
    getAllUpdateRequest() {
      this.search.type='accountholder';
      this.isLoading = true;
      updateRequest
          .paginate(this.search, this.page)
          .then(response => {
            this.update_requests=response.data.data;
            this.page = response.data.meta.current_page;
            this.total = response.data.meta.total;
            this.perPage = response.data.meta.per_page;
            this.isLoading = false;
          })
          .catch((err) => {

          });
    },
    reviewRequest(itemId) {
      this.request_id=itemId;
      this.findDetail();
      this.dialog=true;
    },
    findDetail(){
      updateRequest
      .show(this.request_id)
          .then(response=>{
            this.updateRequestDetail=response.data.updateRequest;
            this.remarks=this.updateRequestDetail.remark;
            this.type=this.updateRequestDetail.type;
          })
          .catch((err) => {

          });
    },
    approvedUpdateRequest() {
      this.buttonLoadingApprove=true;
      updateRequest
      .update(this.request_id,{'remark':this.remarks,'status':'approved','type':this.type})
          .then(response=>{

            this.$snotify.success("Request approved");

          })
          .catch((err) => {

          }).finally(() =>{
            this.buttonLoadingApprove = false
            this.refresh();
            this.dialog=false
      });
    },
    disapprovedUpdateRequest() {
      this.buttonLoadingDisapprove=true;
      updateRequest
      .update(this.request_id,{'remark':this.remarks,'status':'rejected','type':this.type})
          .then(response=>{

            this.$snotify.success("Request disapproved");
          })
          .catch((err) => {

          }).finally(() =>{
        this.buttonLoadingDisapprove = false
        this.refresh();
        this.dialog=false
      });
    },
    close(){
      this.changed_data=[];
      this.remarks='';
      this.type='';
      this.dialog=false;
    },
    refresh(){
      this.changed_data=[];
      this.remarks='';
      this.getAllUpdateRequest();
      this.dialog=false;
      this.type='';
      this.$store.dispatch("GET_STATS");
    },
    getCurrentUser() {
      this.currentUser =  this.$store.getters.currentUser;
    },
    viewAccountHolderInfo(accountHolderId) {
      this.$router.push({
        name: 'accountholder-edit',
        params: {accountholderId: accountHolderId}
      })
    },
  },
  mounted() {
    this.getCurrentUser();
    this.getAllScore();
    this.getAllUpdateRequest();
    if(this.request_id){
      this.findDetail();
    }
  }
}
</script>